import React from 'react';
import Footer from '../../../components/public/footer/Footer';
import LoginHeader from '../../../components/public/loginHeader/LoginHeader';
import './PrivacyPolicyPage.css'; // Import your CSS file for styling

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="privacy-container">
      <LoginHeader />
      <main className="privacy-main-content">
        <section className="privacy-section">
          <h1 className="privacy-title">Privacy Policy</h1>
          <h2 className="policy-updated">Policy last updated 07/10/2024</h2>
          <p>
            At Phonetica, we value your privacy and are committed to protecting
            your personal data. This Privacy Policy ("Policy") explains how we
            collect, use, disclose, and safeguard your information when you use
            our app.
          </p>
        </section>
        <section className="privacy-section">
          <h2>1. Information We Collect</h2>
          <p>
            We may collect certain information from you when you use our app,
            including personal information such as your name, email address, and
            usage data.
          </p>
        </section>
        <section className="privacy-section">
          <h2>2. How We Use Your Information</h2>
          <p>
            We use the information we collect to provide and improve our app,
            communicate with you, personalize your experience, and ensure the
            security of our services.
          </p>
        </section>
        <section className="privacy-section">
          <h2>3. Sharing of Your Information</h2>
          <p>
            We do not sell, trade, or otherwise transfer your personal
            information to third parties without your consent, except as
            outlined in this Policy or required by law.
          </p>
        </section>
        <section className="privacy-section">
          <h2>4. Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to
            protect your data from unauthorized access, use, or disclosure.
          </p>
        </section>
        <section className="privacy-section">
          <h2>5. Your Choices and Rights</h2>
          <p>
            You have the right to access, update, or delete your personal
            information. You may also opt-out of receiving promotional
            communications from us.
          </p>
        </section>
        <section className="privacy-section">
          <h2>6. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the revised Policy on our app.
          </p>
        </section>
        <section className="privacy-section">
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or our privacy
            practices, please contact us at support@phonetica.ai.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
