import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleQuestionClick = () => {
    navigate('/questions');
  };

  return (
    <footer className="footer">
      <div className="footer-left">
        <p>© 2024, Phonetica.ai - All rights reserved.</p>
      </div>
      <nav className="footer-nav">
        <Link to="/terms-of-use" className="footer-link">
          Terms of Use
        </Link>
        <Link to="/privacy-policy" className="footer-link">
          Privacy Policy
        </Link>
        <button
          className="footer-question-button"
          onClick={handleQuestionClick}
        >
          Questions?
        </button>
      </nav>
    </footer>
  );
};

export default Footer;
