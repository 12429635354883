import React from 'react';
import Footer from '../../../components/public/footer/Footer';
import LoginHeader from '../../../components/public/loginHeader/LoginHeader';
import './TermsOfUsePage.css'; // Import your CSS file for styling

const TermsOfUsePage: React.FC = () => {
  return (
    <div className="terms-container">
      <LoginHeader />
      <main className="terms-main-content">
        <section className="terms-section">
          <h1 className="terms-title">Terms of Use</h1>
          <h2 className="terms-updated">
            Terms of use last updated 07/10/2024
          </h2>
          <p>
            Welcome to Phonetica, your all-in-one language learning toolkit.
            These Terms of Use ("Terms") govern your access to and use of the
            Phonetica app and any related uses provided by Phonetica.ai ("we,"
            "our," or "us").
          </p>
          <p>
            By accessing or using our app, you agree to be bound by these Terms
            and our Privacy Policy. If you do not agree to these Terms or our
            Privacy Policy, do not use the app.
          </p>
        </section>
        <section className="terms-section">
          <h2>1. Acceptance of Terms</h2>
          <p>
            By using the Phonetica app, you agree to these Terms. These Terms
            may be updated from time to time, and your continued use of the app
            after any changes constitutes your acceptance of the revised Terms.
          </p>
        </section>
        <section className="terms-section">
          <h2>2. Description of Use</h2>
          <p>
            Phonetica provides users with tools and resources for language
            learning, including flashcards, generative AI-driven content
            creation, and more.
          </p>
        </section>
        <section className="terms-section">
          <h2>3. User Responsibilities</h2>
          <p>
            You are responsible for maintaining the confidentiality of your
            account and password. You agree to provide accurate and complete
            information and to comply with all applicable laws and regulations
            when using the app.
          </p>
        </section>
        <section className="terms-section">
          <h2>4. Privacy Policy</h2>
          <p>
            Your use of the app is also governed by our Privacy Policy, which
            explains how we collect, use, and protect your personal data. By
            using the app, you consent to our Privacy Policy.
          </p>
        </section>
        <section className="terms-section">
          <h2>5. Account and Security</h2>
          <p>
            You are responsible for all activities that occur under your
            account. Notify us immediately of any unauthorized access or use of
            your account.
          </p>
        </section>
        <section className="terms-section">
          <h2>6. Content Ownership</h2>
          <p>
            You retain ownership rights to any content you create or upload to
            the app. By uploading content, you grant us a license to use,
            modify, and distribute it as necessary for app functionality.
          </p>
        </section>
        <section className="terms-section">
          <h2>7. Prohibited Activities</h2>
          <p>
            You agree not to engage in any prohibited activities, including but
            not limited to violating any laws or regulations, interfering with
            the app's security, or uploading malicious content.
          </p>
        </section>
        <section className="terms-section">
          <h2>8. Termination</h2>
          <p>
            We reserve the right to terminate or suspend your access to the app
            at any time for any reason without notice.
          </p>
        </section>
        <section className="terms-section">
          <h2>9. Changes to Terms</h2>
          <p>
            We may update these Terms periodically. We will notify you of any
            changes by posting the revised Terms on our app.
          </p>
        </section>
        <section className="terms-section">
          <h2>10. Disclaimer of Warranties</h2>
          <p>
            The app is provided "as is" without warranties of any kind. We do
            not guarantee that the app will be error-free or continuously
            available.
          </p>
        </section>
        <section className="terms-section">
          <h2>11. Limitation of Liability</h2>
          <p>
            In no event shall Phonetica.ai be liable for any indirect,
            incidental, special, consequential, or punitive damages.
          </p>
        </section>
        <section className="terms-section">
          <h2>12. Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of the state of Massachusetts, without regard to its conflict
            of law provisions.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default TermsOfUsePage;
