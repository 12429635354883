import React, { useEffect, useRef, useState } from 'react';
import './TranslationTooltip.css';

interface TranslationTooltipProps {
  word: string;
  translation: string;
  phonetic: string | null;
  showPhonetics: boolean;
  saved: boolean;
  fontSize?: 'small' | 'large';
  children: React.ReactNode;
}

const TranslationTooltip: React.FC<TranslationTooltipProps> = ({
  word,
  translation,
  phonetic,
  showPhonetics,
  saved,
  fontSize = 'large',
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipContentRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (visible && tooltipContentRef.current) {
      const rect = tooltipContentRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top, // Adjust this value to position the tooltip above the word
        left: rect.left + rect.width / 2, // Center the tooltip horizontally
      });
    }
  }, [visible]);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {visible && (
        <div
          className="tooltip"
          style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
        >
          {/* <div 
            className="tooltip-save"
            onClick={handleSaveClick}
          >
            {isSaved ? <FaBookmark className='tooltip-save-icon'/> : <FaRegBookmark className='tooltip-save-icon'/>}
            <span className='tooltip-save-name'>{isSaved ? "Saved" : "Save"}</span>
          </div> */}
          <div className="tooltip-words">
            {phonetic && <span className="tooltip-phonetic">{phonetic}</span>}
            <span className="tooltip-word">{word}</span>
            <span className="tooltip-translation">{translation}</span>
          </div>
        </div>
      )}
      <span className="tooltip-content" ref={tooltipContentRef}>
        <span
          className={`tooltip-content-phonetic ${fontSize}`}
          style={{ visibility: showPhonetics ? 'visible' : 'hidden' }}
        >
          {phonetic}
        </span>
        <span
          className={`tooltip-content-word ${visible ? 'highlight' : ''} ${fontSize}`}
        >
          {children}
        </span>
      </span>
    </div>
  );
};

export default TranslationTooltip;
