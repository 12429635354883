import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa'; // Import edit icon from react-icons
import './EditableTranslation.css';

const EditableTranslation: React.FC<{
  translation: string;
  onSave: (newTranslation: string) => void;
}> = ({ translation, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTranslation, setCurrentTranslation] = useState(translation);

  // Toggle editing mode
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Save the edited translation
  const handleSave = () => {
    onSave(currentTranslation);
    setIsEditing(false);
  };

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTranslation(event.target.value);
  };

  // Handle Enter key press to save changes
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="translation-container">
      {isEditing ? (
        <div className="translation-edit">
          <input
            type="text"
            value={currentTranslation}
            onChange={handleInputChange}
            onBlur={handleSave} // Save changes when input loses focus
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </div>
      ) : (
        <>
          <span className="translation">{translation}</span>
          <div className="edit-icon-container">
            <FaEdit
              className="edit-icon"
              title="Edit"
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EditableTranslation;
