import axios from 'axios';
import React, { useState } from 'react';
import { DeckData } from '../../interfaces/decks';

interface EditDeckPopupProps {
  deck: DeckData;
  onSubmit: () => void;
  onClose: () => void;
}

const EditDeckPopup: React.FC<EditDeckPopupProps> = ({
  deck,
  onSubmit,
  onClose,
}) => {
  const [deckName, setDeckName] = useState(deck.name);
  const [newLimit, setNewLimit] = useState(deck.new_limit);
  const [reviewLimit, setReviewLimit] = useState(deck.review_limit);

  const handleSave = async () => {
    try {
      await axios.put(`/api/decks/${deck.id}`, {
        name: deckName,
        new_limit: newLimit,
        review_limit: reviewLimit,
      });
      // Close the popup after successful update
      onSubmit();
    } catch (error) {
      console.error('Error updating deck:', error);
      onClose();
    }
  };

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Edit Deck</h2>
        <div className="phn-popup-form">
          <label>
            Name
            <input
              id="name"
              type="text"
              value={deckName}
              onChange={(e) => setDeckName(e.target.value)}
            />
          </label>
          <label>
            Daily New Card Limit
            <input
              id="newLimit"
              type="text"
              value={newLimit}
              onChange={(e) => {
                const value = e.target.value.trim(); // Trim whitespace
                if (value === '' || isNaN(parseInt(value))) {
                  setNewLimit(0); // Clear the state if input is empty or NaN
                } else {
                  setNewLimit(parseInt(value)); // Parse and set the numeric value
                }
              }}
            />
          </label>
          <label>
            Daily Review Card Limit
            <input
              id="reviewLimit"
              type="text"
              value={reviewLimit}
              onChange={(e) => {
                const value = e.target.value.trim(); // Trim whitespace
                if (value === '' || isNaN(parseInt(value))) {
                  setReviewLimit(0); // Clear the state if input is empty or NaN
                } else {
                  setReviewLimit(parseInt(value)); // Parse and set the numeric value
                }
              }}
            />
          </label>
          <div className="phn-popup-button-container">
            <button className="phn-button" onClick={handleSave}>
              Save
            </button>
            <button className="phn-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDeckPopup;
