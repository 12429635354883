import React, { useState } from 'react';
import Footer from '../../../components/public/footer/Footer';
import LoginHeader from '../../../components/public/loginHeader/LoginHeader';
import './QuestionsPage.css';

// FAQ data structure
const faqData = [
  {
    question: "How does Phonetica's flashcard creation work?",
    answer:
      'Simply enter a phrase in your native language, and Phonetica will provide you with multiple options for flashcards in different formalities with high quality audio in a wide range of unique voices. What normally takes minutes on other platforms takes just seconds with Phonetica.',
  },
  {
    question: 'Does Phonetica use Spaced Repetition?',
    answer:
      'Yes! Phonetica uses a form of Spaced Repetition for flashcard review. This helps you learn new words by repeating them at the optimal times for your learning.',
  },
  {
    question: 'How is Phonetica different from Anki?',
    answer:
      'While Anki is a powerful general-purpose flashcard tool that requires manual card creation, Phonetica is specifically designed for language learning with automated card creation. Instead of spending time creating cards, recording audio, and managing your deck structure, Phonetica instantly generates high-quality flashcards with natural pronunciation audio. Our system also automatically implements spaced repetition and creates personalized reading content based on your vocabulary, making the entire language learning process more efficient and effective.',
  },
  {
    question: "What makes Phonetica's audio different from other platforms?",
    answer:
      'Unlike other platforms that use robotic text-to-speech, Phonetica provides high-quality, natural-sounding audio with proper pronunciation. You can choose from multiple voices to hear different accents and speaking styles.',
  },
  {
    question: 'What is "customized comprehensible input"?',
    answer:
      "These are stories and content generated specifically for your learning level and vocabulary needs. Reading is an incredibly powerful method for language learning, but its often difficult to find content that's at the right level. Instead of struggling with content that's too easy or too difficult, Phonetica creates customized stories that incorporate the words you're learning while maintaining a difficulty level that's perfect for you.",
  },
  {
    question: 'How many languages does Phonetica support?',
    answer:
      "Phonetica supports 42 languages and dialects, including many that aren't available on other platforms. This includes major languages like English, Spanish, and Chinese, as well as regional variants and less commonly taught languages.",
  },
  {
    question: "What's included in the free trial?",
    answer:
      'Our 7-day free trial gives you full access to all features, including flashcard creation and customized story generation. You can test everything Phonetica has to offer before choosing a subscription plan.',
  },
  {
    question: 'What are the subscription options?',
    answer:
      'We offer three flexible plans: Monthly ($10/month), 6-Month ($40 total, $6.67/month), and our best-value Annual plan ($60 total, $5/month). All plans include full access to all features and languages.',
  },
  {
    question: 'Can I track my learning progress?',
    answer:
      'Yes! Phonetica includes learning analytics to help you track your progress, understand your learning patterns, and identify areas for improvement. This helps you stay motivated and focused on your language learning goals.',
  },
  {
    question: 'How is Phonetica different from other language learning apps?',
    answer:
      'Phonetica stands out by offering rapid flashcard creation, realistic audio pronunciation, customized comprehensible input, and detailed learning analytics - all specifically designed for language learning. While other platforms might offer some of these features, Phonetica combines them all in an efficient, user-friendly package.',
  },
];

const QuestionsPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="questions-container">
      <LoginHeader />
      <main className="questions-main-content">
        <section className="questions-section">
          <h1 className="questions-title">Frequently Asked Questions</h1>
        </section>

        <section className="faq-section">
          {faqData.map((faq, index) => (
            <div key={index} className="faq-item">
              <button
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <span className="faq-icon">
                  {activeIndex === index ? '−' : '+'}
                </span>
              </button>
              <div
                className={`faq-answer ${activeIndex === index ? 'active' : ''}`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </section>

        <section className="questions-section contact-section">
          <h2 className="questions-contact-info">Still Have Questions?</h2>
          <p>
            We're here to help! Reach out to our support team at{' '}
            <a href="mailto:support@phonetica.ai">support@phonetica.ai</a>
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default QuestionsPage;
