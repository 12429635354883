import React from 'react';

interface ConfirmDeletePopupProps {
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmDeletePopup: React.FC<ConfirmDeletePopupProps> = ({
  onClose,
  onConfirm,
  message,
}) => {
  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Confirm Deletion</h2>
        <p>{message}</p>
        <div className="phn-popup-button-container">
          <button className="phn-button" onClick={onConfirm}>
            Yes
          </button>
          <button className="phn-cancel-button" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeletePopup;
