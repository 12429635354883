import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LanguageSelect from './components/private/languageSelect/LanguageSelect';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './context/PrivateRoute';
import { UserProvider } from './context/UserContext';
import SidebarLayout from './layouts/sidebarLayout/SidebarLayout';
import AddCardPage from './pages/private/addCard/AddCardPage';
import CardsPage from './pages/private/cards/CardsPage';
import DashboardPage from './pages/private/dashboard/DashboardPage';
import DecksPage from './pages/private/decks/DecksPage';
import PaymentPage from './pages/private/payment/PaymentPage';
import ReadStoryPage from './pages/private/readStory/ReadStoryPage';
import ReviewPage from './pages/private/review/ReviewPage';
import StoriesPage from './pages/private/stories/StoriesPage';
import VocabPage from './pages/private/vocab/VocabPage';
import HomePage from './pages/public/home/HomePage';
import LoginPage from './pages/public/login/LoginPage';
import PrivacyPolicyPage from './pages/public/privacyPolicy/PrivacyPolicyPage';
import QuestionsPage from './pages/public/questions/QuestionsPage';
import SignupPage from './pages/public/signup/SignupPage';
import TermsOfUsePage from './pages/public/termsOfUse/TermsOfUsePage';
import stripePromise from './stripe';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <UserProvider>
          <Elements stripe={stripePromise}>
            <Router>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/terms-of-use" element={<TermsOfUsePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/questions" element={<QuestionsPage />} />

                {/* Private Routes */}
                <Route
                  path="/language-select"
                  element={
                    <PrivateRoute>
                      <LanguageSelect />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/payment"
                  element={
                    <PrivateRoute>
                      <PaymentPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <SidebarLayout />
                    </PrivateRoute>
                  }
                >
                  <Route path="dashboard" element={<DashboardPage />} />
                  <Route path="decks" element={<DecksPage />} />
                  <Route path="cards" element={<CardsPage />} />
                  <Route path="vocab" element={<VocabPage />} />
                  <Route path="stories" element={<StoriesPage />} />
                  <Route path="read-story" element={<ReadStoryPage />} />
                  <Route path="add-card" element={<AddCardPage />} />
                  <Route path="review" element={<ReviewPage />} />
                </Route>
              </Routes>
            </Router>
          </Elements>
        </UserProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
