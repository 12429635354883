import React, { useEffect, useRef, useState } from 'react';
import { FaCirclePause, FaCirclePlay, FaSpinner } from 'react-icons/fa6';
import './PlayButton.css';

interface PlayButtonProps {
  src: string | null;
  onPlay?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  autoPlay?: boolean;
}

const PlayButton: React.FC<PlayButtonProps> = ({
  src,
  onPlay,
  disabled,
  isLoading,
  autoPlay,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (src && audioRef.current && !disabled && !isLoading) {
      const audioElement = audioRef.current;

      const playAudio = () => {
        audioElement
          .play()
          .catch((error) => console.error('Autoplay failed:', error));
        setIsPlaying(true);
      };

      const handleEnded = () => setIsPlaying(false);

      if (autoPlay) {
        playAudio();
      }

      audioElement.addEventListener('ended', handleEnded);

      return () => {
        audioElement.removeEventListener('ended', handleEnded);
        audioElement.pause();
        setIsPlaying(false);
      };
    }
  }, [src, disabled, isLoading, autoPlay]);

  const handleClick = () => {
    if (onPlay) {
      onPlay();
    }
    if (audioRef.current && !disabled && !isLoading) {
      if (isPlaying) {
        audioRef.current?.pause();
        setIsPlaying(false);
      } else {
        audioRef.current?.play();
        setIsPlaying(true);
      }
    }
  };

  return (
    <>
      <button
        className={`phn-play-button ${isLoading ? 'loading' : isPlaying ? 'playing' : ''}`}
        onClick={handleClick}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <FaSpinner className="fa-spin" />
        ) : isPlaying ? (
          <FaCirclePause />
        ) : (
          <FaCirclePlay />
        )}
      </button>
      {src && <audio ref={audioRef} src={src} />}
    </>
  );
};

export default PlayButton;
