import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { Language } from '../../interfaces/language';

interface ProfileUpdatePopupProps {
  onClose: () => void;
  onSubmit: () => void;
}

const ProfileUpdatePopup: React.FC<ProfileUpdatePopupProps> = ({
  onSubmit,
  onClose,
}) => {
  const { userData } = useUser();
  const [name, setName] = useState(userData?.name);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>(
    userData?.native_language_code || ''
  );
  const [timezone, setTimezone] = useState(userData?.timezone || '');
  const [timezones, setTimezones] = useState<string[]>([]);

  const fetchTimezones = useCallback(async () => {
    try {
      const response = await axios.get('/api/misc/timezones');
      setTimezones(response.data);
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  }, []);

  const fetchLanguages = useCallback(async () => {
    try {
      const response = await axios.get('/api/languages/supported');
      // Convert API response object into an array of Language objects
      const languagesArray: Language[] = Object.keys(response.data).map(
        (code) => ({
          code,
          name: response.data[code],
        })
      );

      setLanguages(languagesArray);
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  }, []);

  useEffect(() => {
    fetchTimezones();
    fetchLanguages();
  }, [fetchTimezones, fetchLanguages]);

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.put('/api/users', {
        name: name,
        timezone: timezone,
        native_language_code: selectedLanguageCode,
      });
      onSubmit();
    } catch (error) {
      console.error('Error updating profile:', error);
      onClose();
    }
  };

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Update Profile</h2>
        <div className="phn-popup-form">
          <label>
            Name:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Native Language
            <select
              id="language"
              value={selectedLanguageCode}
              onChange={(e) => setSelectedLanguageCode(e.target.value)}
            >
              <option value="">Select Language</option>
              {languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </select>
          </label>
          <label>
            Timezone:
            <select
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              required
            >
              <option value="" disabled>
                Select your timezone
              </option>
              {timezones.map((tz) => (
                <option key={tz} value={tz}>
                  {tz.replace('_', ' ')}
                </option>
              ))}
            </select>
          </label>
          <div className="phn-popup-button-container">
            <button
              className="phn-button"
              onClick={handleUpdate}
              disabled={!timezone || !name}
            >
              Update
            </button>
            <button className="phn-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpdatePopup;
