import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/private/playButton/PlayButton';
import Footer from '../../../components/public/footer/Footer';
import LoginHeader from '../../../components/public/loginHeader/LoginHeader';
import './HomePage.css';

const languages: { [key: string]: string } = {
  'pt-BR': 'Brazilian Portuguese',
  pt: 'Portuguese',
  es: 'Spanish',
  fr: 'French',
  en: 'English',
  it: 'Italian',
  ga: 'Irish',
  nl: 'Dutch',
  de: 'German',
  da: 'Danish',
  no: 'Norwegian',
  sv: 'Swedish',
  fi: 'Finnish',
  is: 'Icelandic',
  pl: 'Polish',
  cs: 'Czech',
  hu: 'Hungarian',
  ro: 'Romanian',
  bg: 'Bulgarian',
  hr: 'Croatian',
  sr: 'Serbian',
  el: 'Greek',
  lv: 'Latvian',
  lt: 'Lithuanian',
  uk: 'Ukrainian',
  ru: 'Russian',
  tr: 'Turkish',
  iw: 'Hebrew',
  hi: 'Hindi',
  ta: 'Tamil',
  te: 'Telugu',
  ur: 'Urdu',
  'zh-CN': 'Mandarin',
  'zh-TW': 'Taiwanese Mandarin',
  'zh-HK': 'Cantonese',
  ja: 'Japanese',
  ko: 'Korean',
  th: 'Thai',
  vi: 'Vietnamese',
  ms: 'Malay',
  id: 'Indonesian',
  tl: 'Philipino',
};

const useIncrementingNumber = (end: number, start = 0, duration = 2000) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTime: number | null = null;
    const step = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      setCount(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }, [start, end, duration]);

  return count;
};

const HomePage: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const languagesSectionRef = useRef<HTMLElement>(null);
  const count = useIncrementingNumber(isVisible ? 42 : 0, 0, 2000);
  const featureRefs = useRef<(HTMLDivElement | null)[]>([]);
  const comparisonChartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          }
        });
      },
      { threshold: 0.3 }
    );

    // Capture the current values of refs
    const currentFeatureRefs = featureRefs.current;
    const currentComparisonChartRef = comparisonChartRef.current;

    currentFeatureRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    if (currentComparisonChartRef) {
      observer.observe(currentComparisonChartRef);
    }

    return () => {
      // Use the captured values in the cleanup function
      currentFeatureRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
      if (currentComparisonChartRef) {
        observer.unobserve(currentComparisonChartRef);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const currentRef = languagesSectionRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home-container">
      <LoginHeader />
      <main className="home-main-content">
        <section className="home-hero-section">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Personalize Your Language Learning Experience
          </motion.h1>
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Easily craft your own flashcards and comprehensible input.
            <br />
            Learn efficiently with content tailored for <b>your goals.</b>
          </motion.h2>
          <motion.div
            className="home-hero-cta"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3>Sound good?</h3>
            <Link to="/signup">
              <motion.button
                className="home-cta-signup-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started Free
              </motion.button>
            </Link>
          </motion.div>
        </section>
        <section className="home-features-section">
          <h1>What Does Phonetica Offer?</h1>
          <div className="home-features">
            <div
              className="home-feature"
              ref={(el) => (featureRefs.current[0] = el)}
            >
              <div className="home-feature-image-container">
                <img
                  className="home-feature-flashcard-image"
                  src="/assets/images/make-flashcards.png"
                  alt="Make Flashcards"
                />
                <img
                  className="home-feature-flashcard-image"
                  src="/assets/images/review-flashcards.png"
                  alt="Review Flashcards"
                />
              </div>
              <div className="home-feature-text">
                <h1>Customized Flashcards</h1>
                <p>
                  With other platforms, making flashcards is a lot of manual
                  work and takes a lot of time.
                </p>
                <br />
                <p>
                  Many people opt to use premade flashcards, but you end up
                  wasting a lot of time on things you don't need to learn.
                </p>
                <br />
                <p>
                  With Phonetica, you can make flashcards in seconds.
                  <b> All you need is a phrase in your native language</b> that
                  you want to learn.
                </p>
              </div>
            </div>
            <div
              className="home-feature-inverse"
              ref={(el) => (featureRefs.current[1] = el)}
            >
              <div className="home-feature-text-inverse">
                <h1>Realistic Audio</h1>
                <p>
                  Other platforms don't even offer the ability to add audio to
                  your flashcards. You have to find your own audio or use
                  machine generated audio that ends up sounding robotic and
                  unnatural.
                </p>
                <br />
                <p>
                  With Phonetica, flashcards and stories come with{' '}
                  <b>realistic audio built in.</b> Select from a variety of high
                  quality voices with <b>proper pronunciation</b>.
                </p>
              </div>
              <div className="home-feature-audio-container">
                <div className="home-feature-audio">
                  <img
                    src={`/assets/flags/zh-CN.svg`}
                    alt={`Chinese flag`}
                    className="language-flag"
                  />
                  <PlayButton src="/assets/audio/chinese_audio_sample.mp3" />
                </div>
                <div className="home-feature-audio">
                  <img
                    src={`/assets/flags/es.svg`}
                    alt={`Spanish flag`}
                    className="language-flag"
                  />
                  <PlayButton src="/assets/audio/spanish_audio_sample.mp3" />
                </div>
                <div className="home-feature-audio">
                  <img
                    src={`/assets/flags/fr.svg`}
                    alt={`French flag`}
                    className="language-flag"
                  />
                  <PlayButton src="/assets/audio/french_audio_sample.mp3" />
                </div>
              </div>
            </div>
            <div
              className="home-feature"
              ref={(el) => (featureRefs.current[2] = el)}
            >
              <div className="home-feature-image-container">
                <img
                  className="home-feature-image"
                  src="/assets/images/custom-stories.png"
                  alt="Stories for Your Level"
                />
              </div>
              <div className="home-feature-text">
                <h1>
                  Customized <br />
                  Comprehensible Input
                </h1>
                <p>
                  Finding comprehensible input that fits your level is almost
                  impossible.
                </p>
                <br />
                <p>
                  With Phonetica, <b>it's easy</b>. You can generate your own
                  stories that fit your level and that{' '}
                  <b>use the vocabulary you're learning</b>.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* New Tutorial Video Section */}
        <section className="home-tutorial-section">
          <h1>See Phonetica in Action</h1>
          <div className="home-tutorial-video-container">
            <iframe
              className="home-tutorial-video"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/fLtr0th0J6k?si=TJLlh9-kPlbUsspw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        <section className="home-solution-section">
          <h1>Why Phonetica?</h1>
          <div className="home-comparison-chart" ref={comparisonChartRef}>
            <div className="home-comparison-chart-column feature-column">
              <div style={{ visibility: 'hidden' }}>Feature</div>
              <div>Time to make a flashcard</div>
              <div>Realistic audio</div>
              <div>Custom comprehensible input</div>
              <div>Learning analytics</div>
              <div>Built for language learning</div>
            </div>
            <div className="home-comparison-chart-column phonetica-column">
              <div>
                <h2>Phonetica</h2>
              </div>
              <div>Seconds</div>
              <div>
                <FaCircleCheck className="home-comparison-column-icon" />
              </div>
              <div>
                <FaCircleCheck className="home-comparison-column-icon" />
              </div>
              <div>
                <FaCircleCheck className="home-comparison-column-icon" />
              </div>
              <div>
                <FaCircleCheck className="home-comparison-column-icon" />
              </div>
            </div>
            <div className="home-comparison-chart-column">
              <div>Other Platforms</div>
              <div style={{ color: 'red' }}>Minutes</div>
              <div>
                <FaCircleXmark
                  className="home-comparison-column-icon"
                  style={{ color: 'red' }}
                />
              </div>
              <div>
                <FaCircleXmark
                  className="home-comparison-column-icon"
                  style={{ color: 'red' }}
                />
              </div>
              <div>
                <FaCircleXmark
                  className="home-comparison-column-icon"
                  style={{ color: 'red' }}
                />
              </div>
              <div>
                <FaCircleXmark
                  className="home-comparison-column-icon"
                  style={{ color: 'red' }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="home-languages-section" ref={languagesSectionRef}>
          <h1>{count} Languages and Counting!</h1>
          <h2>Including dialects not found on other platforms</h2>
          <div className="home-languages-flags">
            {Object.keys(languages).map((code) => (
              <div key={code} className="home-languages-flag">
                <img
                  src={`/assets/flags/${code}.svg`}
                  alt={`${languages[code]} flag`}
                  className="home-language-flag"
                />
                <div className="home-language-name">
                  <h3>{languages[code]}</h3>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="home-pricing-section">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Choose Your Plan
          </motion.h1>
          <motion.p
            className="home-free-trial-text"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            viewport={{ once: true }}
          >
            All plans come with a 7-day free trial
          </motion.p>
          <motion.div
            className="home-pricing-cards"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <motion.div
              className="home-pricing-card"
              whileHover={{ scale: 1.03 }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              <h2>Monthly</h2>
              <div className="home-pricing-card-price-container">
                <p className="home-price">$10</p>
                <p className="home-price-per-month">
                  $10<span>/month</span>
                </p>
              </div>
            </motion.div>
            <motion.div
              className="home-pricing-card featured"
              whileHover={{ scale: 1.03 }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              <div className="home-featured-label">Best Value</div>
              <h2>12 Months</h2>
              <div className="home-pricing-card-price-container">
                <p className="home-original-price">$120</p>
                <p className="home-price">$60</p>
                <p className="home-price-per-month">
                  $5<span>/month</span>
                </p>
              </div>
              <p className="home-savings">Save $60 per year</p>
            </motion.div>
            <motion.div
              className="home-pricing-card"
              whileHover={{ scale: 1.03 }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              <h2>6 Months</h2>
              <div className="home-pricing-card-price-container">
                <p className="home-original-price">$60</p>
                <p className="home-price">$40</p>
                <p className="home-price-per-month">
                  $6.67<span>/month</span>
                </p>
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            className="home-signup-button-container"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            viewport={{ once: true }}
            style={{ marginTop: '60px' }}
          >
            <Link to="/signup">
              <motion.button
                className="home-signup-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started Free!
              </motion.button>
            </Link>
          </motion.div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
