import React from 'react';
import { FaSignInAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import './LoginHeader.css';

const LoginHeader: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  return (
    <header className="login-header-header">
      <div className="login-header-logo">
        <Link to="/" className="login-header-link">
          Phonetica.ai
        </Link>
      </div>
      <div className="login-header-login-bar">
        <button
          className="login-header-login-button"
          onClick={handleLoginClick}
        >
          <FaSignInAlt /> {/* Login icon */}
          Login
        </button>
        <button
          className="login-header-signup-button"
          onClick={() => {
            navigate('/signup');
          }}
        >
          Signup
        </button>
      </div>
    </header>
  );
};

export default LoginHeader;
