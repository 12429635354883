import React from 'react';
import TranslationTooltip from '../translationTooltip/TranslationTooltip';
import './PassageWithTranslation.css';

interface PassageWithTranslationProps {
  original: string[];
  translations: string[];
  phonetics: string[] | null;
  savedWords: string[];
  showPhonetics: boolean;
  languageCode: string;
  align?: 'center' | 'left' | 'right';
  fontSize?: 'small' | 'large';
}

const PassageWithTranslation: React.FC<PassageWithTranslationProps> = ({
  original,
  translations,
  phonetics,
  savedWords,
  showPhonetics,
  languageCode,
  align = 'left',
  fontSize = 'large',
}) => {
  if (original.length !== translations.length) {
    throw new Error('Mismatch between original and translations lengths');
  }

  const gap =
    languageCode.includes('zh') || languageCode.includes('ja') ? '0px' : '5px';

  return (
    <div
      className="passage"
      style={{
        gap: gap,
        justifyContent: align,
        alignItems: align,
        textAlign: align,
      }}
    >
      {original.map((word, index) =>
        translations[index] ? (
          <TranslationTooltip
            key={index}
            word={word}
            translation={translations[index]}
            phonetic={phonetics ? phonetics[index] : null}
            showPhonetics={showPhonetics}
            saved={savedWords.indexOf(word) > -1}
            fontSize={fontSize}
          >
            {word}
          </TranslationTooltip>
        ) : (
          <span className="passage-content">
            {phonetics && (
              <span
                style={{ visibility: showPhonetics ? 'visible' : 'hidden' }}
                className={`passage-phonetic ${fontSize}`}
              >
                {word}
              </span>
            )}
            <span key={index} className={`passage-word ${fontSize}`}>
              {word}
            </span>
          </span>
        )
      )}
    </div>
  );
};

export default PassageWithTranslation;
