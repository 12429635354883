import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FaCirclePause,
  FaCirclePlay,
  FaToggleOff,
  FaToggleOn,
} from 'react-icons/fa6';
import {
  TbLayoutBottombarCollapseFilled,
  TbLayoutBottombarExpandFilled,
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarRightExpandFilled,
} from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import PassageWithTranslation from '../../../components/private/passageWithTranslation/PassageWithTranslation';
import { Story } from '../../../interfaces/stories';
import { getPhoneticName } from '../../../utils/formatting';
import './ReadStoryPage.css';

const ReadStoryPage: React.FC = () => {
  const [storyId, setStoryId] = useState<number | undefined>();
  const [showPhonetics, setShowPhonetics] = useState(false);
  const [nativeLanguageTitle, setNativeLanguageTitle] = useState('');
  const [targetLanguageTitle, setTargetLanguageTitle] = useState('');
  const [languageCode, setLanguageCode] = useState('');
  const [storyAudio, setStoryAudio] = useState('');
  const [story, setStory] = useState<Story[]>();
  const location = useLocation();

  const [currentLineIndex, setCurrentLineIndex] = useState(-1);
  const [showTranslation, setShowTranslation] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  const storySectionRef = useRef<HTMLDivElement>(null);
  const translationSectionRef = useRef<HTMLDivElement>(null);

  const scrollToCenter = useCallback(() => {
    if (storySectionRef.current && currentLineIndex !== -1) {
      const lineElement = storySectionRef.current.children[
        currentLineIndex
      ] as HTMLElement;
      const containerRect = storySectionRef.current.getBoundingClientRect();
      const lineRect = lineElement.getBoundingClientRect();

      // Calculate the visible area of the container
      const visibleTop = containerRect.top;
      const visibleBottom = containerRect.bottom;
      const visibleHeight = visibleBottom - visibleTop;

      // Calculate the desired position
      const desiredPosition =
        lineRect.top + lineRect.height / 2 - visibleTop - visibleHeight / 2;

      // Smoothly scroll to the desired position
      storySectionRef.current.scrollBy({
        top: desiredPosition,
        behavior: 'smooth',
      });
    }
  }, [currentLineIndex]);

  useEffect(() => {
    if (isPlaying) {
      scrollToCenter();
    }
  }, [isPlaying, currentLineIndex, scrollToCenter]);

  useEffect(() => {
    const state = location.state as { selectedStory?: number };
    if (location.state && state.selectedStory) {
      setStoryId(state.selectedStory);
    }
  }, [location.state]);

  const fetchStory = useCallback(async () => {
    try {
      const response = await axios.get(`/api/stories/${storyId}`);
      const data = response.data;
      setLanguageCode(data.language_code);
      setNativeLanguageTitle(data.native_language_title);
      setTargetLanguageTitle(data.target_language_title);
      setStoryAudio(data.audio);
      setStory(data.story_data);
    } catch (error) {
      console.error('Error fetching story data:', error);
    }
  }, [storyId]);

  useEffect(() => {
    if (storyId) {
      fetchStory();
    }
  }, [fetchStory, storyId]);

  const handleScrollStory = (event: React.UIEvent<HTMLDivElement>) => {
    if (storySectionRef.current && translationSectionRef.current) {
      const storyScrollHeight =
        storySectionRef.current.scrollHeight -
        storySectionRef.current.clientHeight;
      const translationScrollHeight =
        translationSectionRef.current.scrollHeight -
        translationSectionRef.current.clientHeight;
      const scrollRatio = translationScrollHeight / storyScrollHeight;
      const transPos = Math.round(
        storySectionRef.current.scrollTop * scrollRatio
      );

      translationSectionRef.current.scrollTop = transPos;
    }
  };

  const handleScrollTranslation = (event: React.UIEvent<HTMLDivElement>) => {
    if (isPlaying) {
      return;
    }
    if (storySectionRef.current && translationSectionRef.current) {
      const storyScrollHeight =
        storySectionRef.current.scrollHeight -
        storySectionRef.current.clientHeight;
      const translationScrollHeight =
        translationSectionRef.current.scrollHeight -
        translationSectionRef.current.clientHeight;
      const scrollRatio = storyScrollHeight / translationScrollHeight;
      const storyPos = Math.round(
        translationSectionRef.current.scrollTop * scrollRatio
      );

      storySectionRef.current.scrollTop = storyPos;
    }
  };

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  const handleHighlight = (index: number) => {
    if (currentLineIndex === index) {
      return;
    }
    setCurrentLineIndex(index);
    if (story && story[index] && story[index].timestamp) {
      const timestamp = story[index].timestamp;
      if (timestamp && typeof timestamp.start === 'number') {
        const startTime = timestamp.start / 1000;
        setCurrentTime(startTime);
        if (audioRef.current) {
          audioRef.current.currentTime = startTime;
        }
      }
    }
  };

  const togglePhonetics = () => {
    setShowPhonetics(!showPhonetics);
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current && story) {
      const currentTime = Math.floor(audioRef.current.currentTime * 1000);
      setCurrentTime(currentTime);

      // Find the current line based on the audio timestamp
      const currentIndex = story.findIndex(
        (line) =>
          line.timestamp &&
          currentTime >= line.timestamp.start &&
          currentTime < line.timestamp.end
      );

      if (currentIndex !== -1 && currentIndex !== currentLineIndex) {
        setCurrentLineIndex(currentIndex);
      }

      // Check if we've reached the end of the audio
      if (audioRef.current.currentTime >= audioRef.current.duration) {
        setIsPlaying(false);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(Math.floor(audioRef.current.duration * 1000));
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seekTime = Number(e.target.value);
    setCurrentTime(seekTime);
    if (audioRef.current) {
      audioRef.current.currentTime = seekTime / 1000;
    }
  };

  const formatTime = (time: number) => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  return (
    <div className="read-story-container">
      <h1>{targetLanguageTitle}</h1>
      <h2 style={{ fontSize: '1rem', fontWeight: 'normal' }}>
        {nativeLanguageTitle}
      </h2>
      <div className="read-story-content-container">
        <div
          className={`story-section ${showTranslation ? 'small' : ''}`}
          ref={storySectionRef}
          onScroll={handleScrollStory}
        >
          {story &&
            story.map((line, index) => (
              <div
                className={`story-line ${currentLineIndex === index ? 'selected' : ''}`}
                key={index}
                onClick={() => handleHighlight(index)}
              >
                <span style={{ fontWeight: 'bold' }}>{index + 1}</span>
                <PassageWithTranslation
                  original={line.original_parsed}
                  translations={line.translations}
                  phonetics={line.phonetics}
                  savedWords={[]}
                  showPhonetics={showPhonetics}
                  languageCode={languageCode}
                  fontSize="small"
                />
              </div>
            ))}
        </div>
        <div className="toggle-button-container">
          <button
            name="Toggle Translation"
            className="toggle-button phn-button-tooltip"
            onClick={toggleTranslation}
            tooltip-data="Toggle Translation"
          >
            {window.innerWidth > 768 ? (
              showTranslation ? (
                <TbLayoutSidebarRightCollapseFilled />
              ) : (
                <TbLayoutSidebarRightExpandFilled />
              )
            ) : showTranslation ? (
              <TbLayoutBottombarCollapseFilled />
            ) : (
              <TbLayoutBottombarExpandFilled />
            )}
          </button>
          {languageCode.includes('zh') || languageCode.includes('ja') ? (
            <button
              name={`Toggle ${getPhoneticName(languageCode)}`}
              className="toggle-button phn-button-tooltip"
              onClick={togglePhonetics}
              tooltip-data={`Toggle ${getPhoneticName(languageCode)}`}
            >
              {showPhonetics ? <FaToggleOn /> : <FaToggleOff />}
            </button>
          ) : null}
        </div>
        <div
          className={`translation-section ${showTranslation ? 'small' : ''}`}
          ref={translationSectionRef}
          onScroll={handleScrollTranslation}
          style={{ display: showTranslation ? 'flex' : 'none' }}
        >
          {story &&
            story.map((line, index) => (
              <div
                className={`translation-line ${currentLineIndex === index ? 'selected' : ''}`}
                key={index}
                onClick={() => handleHighlight(index)}
              >
                <span style={{ fontWeight: 'bold' }}>{index + 1}</span>
                <div className="translation-text">
                  <span>{line.original_translated}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="audio-player">
        <button onClick={toggleAudio} className="audio-toggle-button">
          {isPlaying ? <FaCirclePause /> : <FaCirclePlay />}
        </button>
        <input
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          onChange={handleSeek}
          className="audio-progress"
          step={1}
        />
        <span className="audio-time">
          {formatTime(currentTime)} / {formatTime(duration)}
        </span>
        <audio
          ref={audioRef}
          src={storyAudio}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={handleAudioEnd}
        />
      </div>
    </div>
  );
};

export default ReadStoryPage;
