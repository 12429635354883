import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../../context/UserContext';
import './CreateStoryPopup.css'; // Importing CSS for the popup
interface CreateStoryPopupProps {
  onClose: () => void; // Function to close the popup
  onSubmit: () => void; // Function to submit the popup
}

const CreateStoryPopup: React.FC<CreateStoryPopupProps> = ({
  onSubmit,
  onClose,
}) => {
  const { userData } = useUser();
  const [theme, setTheme] = useState<string>('');
  const [vocab, setVocab] = useState<number>(50);
  const [difficulty, setDifficulty] = useState<string>('beginner');
  const [style, setStyle] = useState<string>('narrative');
  const [languageCode] = useState<string>(
    userData?.learning_language_code || ''
  );
  const [vocabSizeData, setVocabSizeData] = useState<number>(0);

  const fetchVocabSizeData = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vocabulary/size/${languageCode}`);
      setVocabSizeData(response.data);
    } catch (error) {
      console.error('Error fetching vocabulary size data:', error);
    }
  }, [languageCode]);

  const handleCreateStory = async () => {
    try {
      const payload = {
        theme: theme,
        n_vocab: vocab,
        difficulty: difficulty,
        style: style,
      };
      await axios.post('/api/stories', payload);
      onSubmit();
    } catch (error: any) {
      console.error('Error creating story:', error);
      toast.error(`Error: ${error.response.data.detail}`);
      onClose();
    }
  };

  useEffect(() => {
    fetchVocabSizeData();
  }, [fetchVocabSizeData]);

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Create New Story</h2>
        <div className="phn-popup-form">
          <label htmlFor="name">
            Theme (optional)
            <input
              id="theme"
              type="text"
              value={theme}
              onChange={(e) => setTheme(e.target.value)}
            />
          </label>
          <label htmlFor="name" style={{ gap: '5px' }}>
            Vocab
            <div className="create-story-selections">
              <div
                className={`create-story-selections-item ${vocab === 0 ? 'active' : ''} ${
                  vocabSizeData < 50 ? 'disabled' : ''
                }`}
                onClick={() => {
                  if (vocabSizeData >= 50) {
                    setVocab(0);
                  }
                }}
              >
                My Vocab
              </div>
              <div
                className={`create-story-selections-item ${vocab === 50 ? 'active' : ''}`}
                onClick={() => {
                  setVocab(50);
                }}
              >
                50 Words
              </div>
              <div
                className={`create-story-selections-item ${vocab === 100 ? 'active' : ''}`}
                onClick={() => {
                  setVocab(100);
                }}
              >
                100 Words
              </div>
              <div
                className={`create-story-selections-item ${vocab === 200 ? 'active' : ''}`}
                onClick={() => {
                  setVocab(200);
                }}
              >
                200 Words
              </div>
            </div>
          </label>
          {vocabSizeData < 50 && (
            <div className="vocab-requirement-message">
              You need {50 - vocabSizeData} more words to use your vocabulary
            </div>
          )}
          <label htmlFor="name" style={{ gap: '5px' }}>
            Difficulty
            <div className="create-story-selections">
              <div
                className={`create-story-selections-item ${difficulty === 'beginner' ? 'active' : ''}`}
                onClick={() => {
                  setDifficulty('beginner');
                }}
              >
                Beginner
              </div>
              <div
                className={`create-story-selections-item ${difficulty === 'intermediate' ? 'active' : ''}`}
                onClick={() => {
                  setDifficulty('intermediate');
                }}
              >
                Intermediate
              </div>
              <div
                className={`create-story-selections-item ${difficulty === 'advanced' ? 'active' : ''}`}
                onClick={() => {
                  setDifficulty('advanced');
                }}
              >
                Advanced
              </div>
            </div>
          </label>
          <label htmlFor="name" style={{ gap: '5px' }}>
            Style
            <div className="create-story-selections">
              <div
                className={`create-story-selections-item ${style === 'narrative' ? 'active' : ''}`}
                onClick={() => {
                  setStyle('narrative');
                }}
              >
                Narrative
              </div>
              <div
                className={`create-story-selections-item ${style === 'conversation' ? 'active' : ''}`}
                onClick={() => {
                  setStyle('conversation');
                }}
              >
                Conversation
              </div>
              <div
                className={`create-story-selections-item ${style === 'first-person' ? 'active' : ''}`}
                onClick={() => {
                  setStyle('first-person');
                }}
              >
                First Person
              </div>
            </div>
          </label>
        </div>
        <div className="phn-popup-button-container">
          <button className="phn-button" onClick={handleCreateStory}>
            Create
          </button>
          <button className="phn-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateStoryPopup;
