import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

/* stripePromise.then((stripe) => {
  if (stripe) {
    console.log('Stripe has been loaded successfully');
  } else {
    console.error('Failed to load Stripe');
  }
}); */

export default stripePromise;
