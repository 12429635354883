export const getPhoneticName = (code: string) => {
  if (code.includes('zh')) {
    return 'Pinyin';
  } else if (code === 'ja') {
    return 'Rōmaji';
  } else if (code === 'ko') {
    return 'Hangul';
  }
  return code;
};

export const isPhoneticsSupported = (code: string) => {
  return code.includes('zh') || code === 'ja';
};
