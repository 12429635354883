import React, { useState } from 'react';
import './DataTable.css';

interface DataTableProps {
  headers: string[];
  rows: Array<any[]>;
  widths?: string[];
  onRowClick?: (index: number) => void;
  isSelectable?: boolean;
  selectedItems?: number[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<number[]>>;
  loading?: boolean;
  itemsPerPage?: number;
}

const DataTable: React.FC<DataTableProps> = ({
  headers,
  rows,
  widths,
  onRowClick,
  isSelectable = false,
  selectedItems = [],
  setSelectedItems,
  loading = false,
  itemsPerPage = 10,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  if (!widths) {
    widths = new Array(headers.length).fill('auto');
  }

  const handleRowSelection = (index: number) => {
    const globalIndex = (currentPage - 1) * itemsPerPage + index;
    onRowClick?.(globalIndex);
    if (isSelectable && setSelectedItems) {
      setSelectedItems((prevSelectedItems) => {
        const updatedItems = prevSelectedItems.includes(index)
          ? prevSelectedItems.filter((i) => i !== index)
          : [...prevSelectedItems, index];
        return updatedItems;
      });
    }
  };

  const renderSkeletonRows = () => {
    return Array(itemsPerPage)
      .fill(0)
      .map((_, index) => (
        <tr key={`skeleton-${index}`} className="phn-data-table-row">
          <td className="phn-data-table-index"></td>
          {headers.map((_, colIndex) => (
            <td key={colIndex} style={{ width: widths?.[colIndex] }}>
              <div
                className="skeleton"
                style={{
                  height: '20px',
                  width: '80%',
                  borderRadius: '4px',
                  margin: '5px 0',
                }}
              />
            </td>
          ))}
        </tr>
      ));
  };

  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const paginatedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (paginatedRows.length === 0 && currentPage > 1) {
    setCurrentPage(currentPage - 1);
  }

  return (
    <>
      <table className="phn-data-table">
        <thead>
          <tr className="phn-data-table-header">
            <th className="phn-data-table-index"></th>
            {headers.map((header, index) => (
              <th key={index} style={{ width: widths?.[index] }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading
            ? renderSkeletonRows()
            : paginatedRows.map((row, index) => (
                <tr
                  key={index}
                  className={`phn-data-table-row ${selectedItems.includes(index) ? 'selected' : ''}`}
                  onClick={() => handleRowSelection(index)}
                >
                  <td className="phn-data-table-index">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  {row.map((data, colIndex) => (
                    <td key={colIndex} style={{ width: widths?.[colIndex] }}>
                      {data}
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
      {!loading && totalPages > 1 && (
        <div className="phn-data-table-pagination">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default DataTable;
