import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FaBars,
  FaFireAlt,
  FaHome,
  FaSignOutAlt,
  FaStar,
  FaUserCircle,
} from 'react-icons/fa';
import { MdAutoStories } from 'react-icons/md';
import { TbCardsFilled, TbVocabulary } from 'react-icons/tb';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useUser } from '../../../context/UserContext';
import { Language } from '../../../interfaces/language';
import ChangePasswordPopup from '../../../popups/changePassword/ChangePasswordPopup';
import ProfileUpdatePopup from '../../../popups/updateProfile/ProfileUpdatePopup';
import './Sidebar.css';

interface SidebarProps {
  onToggle: (isCollapsed: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onToggle }) => {
  const { logout } = useAuth();
  const { userData, refreshUser } = useUser();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isProfilePopupVisible, setProfilePopupVisible] = useState(false);
  const [isUpdateProfilePopupVisible, setUpdateProfilePopupVisible] =
    useState(false);
  const [isChangePasswordPopupVisible, setChangePasswordPopupVisible] =
    useState(false);
  const [selectedLanguageName, setSelectedLanguageName] = useState('');
  const [languages, setLanguages] = useState<Language[]>([]);
  const [isLanguagePopupVisible, setLanguagePopupVisible] = useState(false);
  const [isLanguagePopupFadingOut, setIsLanguagePopupFadingOut] =
    useState(false);
  const [isProfilePopupFadingOut, setIsProfilePopupFadingOut] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchLanguages = useCallback(async (learning_language_code: string) => {
    const response = await axios.get('/api/languages/supported/preferred');
    const data: {
      [key: string]: { name: string; has_decks: boolean; deck_count: number };
    } = response.data;
    setSelectedLanguageName(data[learning_language_code].name);
    const languagesArray: Language[] = Object.keys(data).map((code) => ({
      code,
      name: data[code].name,
      has_decks: data[code].has_decks,
      deck_count: data[code].deck_count,
    }));
    setLanguages(languagesArray);
  }, []);

  useEffect(() => {
    if (userData?.learning_language_code) {
      fetchLanguages(userData?.learning_language_code);
      console.log('fetchLanguages');
    }
  }, [fetchLanguages, userData?.learning_language_code]);

  const toggleSidebar = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    onToggle(newCollapsedState);
  };

  const isActive = (path: string) => {
    return location.pathname === path ? 'active' : '';
  };

  const toggleLanguagePopup = () => {
    if (isLanguagePopupVisible) {
      setIsLanguagePopupFadingOut(true);
      setTimeout(() => {
        setLanguagePopupVisible(false);
        setIsLanguagePopupFadingOut(false);
      }, 200); // This should match the animation duration
    } else {
      setLanguagePopupVisible(true);
    }
  };

  const toggleProfilePopup = () => {
    if (isProfilePopupVisible) {
      setIsProfilePopupFadingOut(true);
      setTimeout(() => {
        setProfilePopupVisible(false);
        setIsProfilePopupFadingOut(false);
      }, 200); // This should match the animation duration
    } else {
      setProfilePopupVisible(true);
    }
  };

  // Close popup if clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isProfilePopupVisible &&
        !(event.target as HTMLElement).closest(
          '.sidebar-profile-container, .sidebar-profile-popup'
        )
      ) {
        setProfilePopupVisible(false);
      }
      if (
        isLanguagePopupVisible &&
        !(event.target as HTMLElement).closest(
          '.sidebar-language-flag-container, .sidebar-language-flags-popup'
        )
      ) {
        setLanguagePopupVisible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isProfilePopupVisible, isLanguagePopupVisible]);

  const handleLanguageSelect = async (code: string, name: string) => {
    try {
      setLanguagePopupVisible(false);
      if (code !== userData?.learning_language_code) {
        await axios.put(`/api/users/learning_language/${code}`);

        refreshUser();
        setSelectedLanguageName(name);
        if (location.pathname !== '/dashboard') {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.error('Error updating learning languages:', error);
    }
  };

  const handleManageSubscription = async () => {
    setProfilePopupVisible(false);
    try {
      const response = await axios.post('/api/payment/customer/portal/session');
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      // Optionally, show an error message to the user
    }
  };

  const handlePasswordChangeSubmit = () => {
    setChangePasswordPopupVisible(false);
  };

  const handleProfileUpdateSubmit = () => {
    setUpdateProfilePopupVisible(false);
    refreshUser();
  };

  return (
    <div>
      <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-header">
          <div className="sidebar-menu">
            {!isCollapsed && <div className="sidebar-logo-text">Phonetica</div>}
            <FaBars className="sidebar-menu-icon" onClick={toggleSidebar} />
          </div>
          <nav className="sidebar-nav-links">
            <Link
              to="/dashboard"
              className={`sidebar-nav-link ${isActive('/dashboard')}`}
            >
              <FaHome className="sidebar-nav-icon" />
              {!isCollapsed && 'Home'}
            </Link>
            <Link
              to="/decks"
              className={`sidebar-nav-link ${isActive('/decks')}`}
            >
              <TbCardsFilled className="sidebar-nav-icon" />
              {!isCollapsed && 'Decks'}
            </Link>
            <Link
              to="/vocab"
              className={`sidebar-nav-link ${isActive('/vocab')}`}
            >
              <TbVocabulary className="sidebar-nav-icon" />
              {!isCollapsed && 'Vocab'}
            </Link>
            <Link
              to="/stories"
              className={`sidebar-nav-link ${isActive('/stories')}`}
            >
              <MdAutoStories className="sidebar-nav-icon" />
              {!isCollapsed && 'Stories'}
            </Link>
          </nav>
        </div>
        <div className="sidebar-bottom">
          <div className="sidebar-streak-container">
            <div className="sidebar-streak">
              <FaFireAlt className="sidebar-streak-icon" />
              {userData?.day_streak}
            </div>
            {!isCollapsed && 'Daily Streak'}
          </div>
          {userData?.learning_language_code && (
            <div
              className="sidebar-language-flag-container"
              onClick={toggleLanguagePopup}
            >
              <img
                className="sidebar-language-flag-img"
                src={`/assets/flags/${userData?.learning_language_code}.svg`}
                alt={`${userData?.learning_language_code} flag`}
              />
              {!isCollapsed && selectedLanguageName}
            </div>
          )}
          <div
            className="sidebar-profile-container"
            onClick={toggleProfilePopup}
          >
            <FaUserCircle className="sidebar-nav-icon" />
            {!isCollapsed && userData?.name}
          </div>
        </div>
      </div>
      {(isLanguagePopupVisible || isLanguagePopupFadingOut) && (
        <div
          className={`sidebar-language-flags-popup ${isLanguagePopupFadingOut ? 'fade-out' : ''}`}
        >
          {/* <div 
              className='language-choice'
              onClick={(e) => {
                handleLanguageSelect("all", "All");
              }}
            >
              <img
                className="language-flag-img"
                src={`/assets/flags/all.svg`}
                alt={`all flag`}
              />
              <span className="language-name">All</span>
            </div> */}
          {languages.map((lang) => (
            <div
              className={`sidebar-language-choice ${userData?.learning_language_code === lang.code ? 'selected' : ''}`}
              onClick={(e) => {
                handleLanguageSelect(lang.code, lang.name);
              }}
            >
              <img
                className="sidebar-language-popup-flag-img"
                src={`/assets/flags/${lang.code}.svg`}
                alt={`${lang.code} flag`}
              />
              <span className="sidebar-language-name">{lang.name}</span>
              <div className="sidebar-language-star-container">
                {lang.has_decks && <FaStar className="sidebar-language-star" />}
              </div>
            </div>
          ))}
        </div>
      )}
      {(isProfilePopupVisible || isProfilePopupFadingOut) && (
        <div
          className={`sidebar-profile-popup ${isProfilePopupFadingOut ? 'fade-out' : ''}`}
        >
          <button
            className="sidebar-popup-button"
            onClick={() => {
              setProfilePopupVisible(false);
              setUpdateProfilePopupVisible(true);
            }}
          >
            Update Profile
          </button>
          <button
            className="sidebar-popup-button"
            onClick={() => {
              setProfilePopupVisible(false);
              setChangePasswordPopupVisible(true);
            }}
          >
            Change Password
          </button>
          <button
            className="sidebar-popup-button"
            onClick={handleManageSubscription}
          >
            Manage Subscription
          </button>
          {/* <button className="popup-button" onClick={() => navigate('/delete-account')}>Delete Account</button> */}
          <button
            className="sidebar-logout-button"
            onClick={(e) => {
              e.stopPropagation();
              logout();
            }}
          >
            Logout
            <FaSignOutAlt />
          </button>
        </div>
      )}
      {isUpdateProfilePopupVisible && (
        <ProfileUpdatePopup
          onClose={() => setUpdateProfilePopupVisible(false)}
          onSubmit={handleProfileUpdateSubmit}
        />
      )}
      {isChangePasswordPopupVisible && (
        <ChangePasswordPopup
          onClose={() => setChangePasswordPopupVisible(false)}
          onSubmit={handlePasswordChangeSubmit}
        />
      )}
    </div>
  );
};

export default Sidebar;
